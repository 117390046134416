<template>
    <div>
        <div class="header">
            <span class="header_txt">员工审核</span>
        </div>
        <div class="header_box">
            <div class="left">
                <span class="header_span">审核状态:</span>
                <Select v-model="storePrams.status" style="width:200px;">
                    <Option v-for="item in liveList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <span class="header_span">门店:</span>
                <Select v-model="storePrams.shop_id" style="width:200px" @on-open-change="shopSelectFun">
                    <Option v-for="item in shopList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <span class="header_span">员工信息:</span>
                <Input v-model="storePrams.keywords" type="text" style="width: 200px;" placeholder="请输入员工名称或手机号"></Input>
                <button class="header_btn" @click="query">查询</button>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{row}" slot="original_status">
                    <span v-if="row.original_status == 0" class="table_span">{{row.status}}</span>
                    <span v-else-if="row.original_status == 1" class="table_span">已通过</span>
                    <span v-else-if="row.original_status == 2" class="table_span" style="color: #f00;">{{row.status}}</span>
                </template>
                <template slot-scope="{row}" slot="jobs">
                    <span>{{row.name}}</span>
                </template>
                <template slot-scope="{row}" slot="commission">
                    <div style="display:flex;justify-content:center">
                        <span :class="row.commission  ? 'table_btn' : ''" @click="commission(row)">{{row.commission}}</span>
                    </div>
                </template>
                <template slot-scope="{row}" slot="status">
                    <div style="display:flex;justify-content:space-around;">
                        <span v-if="row.original_status == 0" class="table_span" @click="process(row)" style="cursor: pointer;">{{row.status}}</span>
                        <span v-else-if="row.original_status == 1" class="table_span">已通过</span>
                        <span v-else-if="row.original_status == 2" class="table_span" style="color: #f00;">{{row.status}}</span>
                        <span class="table_span" @click="staffDel(row)" style="color: #f00;cursor: pointer;">删除</span>
                    </div>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 佣金提成弹框 -->
        <Modal v-model="commissionModal" title="佣金提成" width="40%">
            <div style="width: 100%;text-align:center;height: 100px;line-height:100px;">
                <span style="margin-right: 20px;">佣金提成</span>
                <Input type="text" v-model="commissionList.commission" style="width: 30%;" placeholder="请输入佣金提成"></Input>%
            </div>
            <div slot="footer">
                <Button @click="commissionModalF">取消</Button>
                <Button type="primary" @click="commissionModalT">确认</Button>
            </div>
        </Modal>
        <!-- 审核弹框 -->
        <Modal v-model="processModal" title="审核" width="30%">
            <div style="text-align:center;">
                是否通过审核
            </div>
            <div slot="footer" style="display:flex;">
                <Button style="width: 50%;" @click="processModalF">拒绝</Button>
                <Button style="width: 50%;" type="primary" @click="processModalT">通过</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {shopSelect, staffList, staffCommission, staffDel, staffExamine} from '@/api/index'
    export default {
        data() {
            return {
                processModal:false,
                processList:{
                    id:'',
                    status:''
                },
                commissionModal:false,
                commissionList:{
                    commission:'',
                    id:'',
                },
                liveList: [
                    {value: '-1',label: '全部'},
                    {value: '0',label: '未审核'},
                    {value: '1',label: '已审核'},
                    {value: '2',label: '已拒绝'},
                ],
                shopList: [],
                //table
                liveTable: [
                    {title: '姓名',key: 'name',align:'center'},
                    {title: '手机号码',key: 'phone',align:'center'},
                    {title: '审核状态',slot: 'original_status',align:'center'},
                    {title: '申请角色',slot: 'jobs',align:'center'},
                    {title: '佣金提成',slot: 'commission',align:'center'},
                    {title: '申请时间',key: 'updated_at',align:'center'},
                    {title: '操作',slot: 'status',align:'center'},
                ],
                dataTable: [],
                //page
                total: 100,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    status:'-1',
                    shop_id:'-1'
                },
            }
        },
        mounted(){
            this.staffList()
        },
        methods: {
            //审核弹框
            process(item){
                this.processList.id = item.id
                this.processModal = true
            },
            processModalF(){
                this.processModal = false
                this.processList.status = 2
                staffExamine(this.processList).then(res => {
                    this.$Message.success(res.msg);
                    this.staffList()
                })
            },
            processModalT(){
                this.processModal = false
                this.processList.status = 1
                staffExamine(this.processList).then(res => {
                    this.$Message.success(res.msg);
                    this.staffList()
                })
            },
            // 员工列表
            staffList(){
                staffList(this.storePrams).then(res => {
                    console.log('员工列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            // 员工删除
            staffDel(item){
                staffDel({id:item.id}).then(res => {
                    this.$Message.success(res.msg)
                    this.staffList()
                })
            },
            //下拉店铺数据 
            shopSelectFun(){
                shopSelect().then(res => {
                    this.shopList = res.data
                })
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            //查询按钮
            query(){
                this.staffList()
            },
            // 佣金提成弹框
            commission(item){
                this.commissionList.commission = item.commission
                this.commissionList.id = item.id
                this.commissionModal = true
            },
            commissionModalF(){
                this.commissionModal = false
            },
            commissionModalT(){
                if(this.commissionList.commission <= 0){
                    this.$Message.error('佣金提成不能小于等于0');
                }else{
                    staffCommission(this.commissionList).then(res => {
                        this.$Message.success(res.msg);
                        this.staffList()
                    })
                    this.commissionModal = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    margin-left: 20px;
    width: 70px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.header_span{
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px 0 10px;
}
.table_span{
    color: #409eff;
}
.table_btn{
    border: 1px solid #ccc;
    display: block;
    border-radius: 5px;
    width: 50%;
    cursor: pointer;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
  background: #fff !important;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
  color: #909395 !important;
}
</style>